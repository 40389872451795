import { readCookie, createCookie } from '../cookies';

const imagesLoaded = require('imagesloaded/imagesloaded');

export default function () {
  const cookie = 'home-intro-animation';
  if (!readCookie(cookie)) {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';
    body.style.maxHeight = '100vh';

    document.addEventListener('DOMContentLoaded', () => {
      const txt = document.querySelector('.intro-text h1').textContent;
      const newTxt = txt.replace(/\w/g, c => `<span>${c}</span>`);

      document.querySelector('.intro-text h1').innerHTML = newTxt;
      const spans = document.querySelectorAll('.intro-text h1 span');
      spans.forEach((span, i) => span.classList.add(`char${i}`));

      const loadingPoints = document.querySelector('.loading-points');
      const points = loadingPoints.textContent;
      const newPoints = points.replace(/\S/g, c => `<span>${c}</span>`);
      loadingPoints.innerHTML = newPoints;

      const pointsSpans = document.querySelectorAll('.loading-points span');
      pointsSpans.forEach((span, i) => span.classList.add(`char${i}`));

      setTimeout(() => {
        const animation = document.querySelector('.animation');
        animation.style.opacity = 1;

        const introTextH1 = document.querySelector('.intro-text h1');
        introTextH1.classList.add('appear');

        const loadingPoints = document.querySelector('.intro-text .loading-points');
        loadingPoints.classList.add('appear');
      }, 10);

      const imageSlides = document.querySelectorAll('.image-slide');

      imagesLoaded(imageSlides, () => {
        setTimeout(() => {
          imageSlides.forEach(slide => slide.classList.add('appear'));

          const introText = document.querySelector('.intro-text');
          introText.classList.add('disappear');
        }, 2500);

        setTimeout(() => {
          const introImage = document.querySelector('.intro-image');
          const introImageRect = introImage.getBoundingClientRect();
          const imageWidth = introImageRect.width;
          const imageHeight = introImageRect.height;
          const offsetX = introImageRect.left;
          const offsetY = introImageRect.top;
          const borders = document.querySelector('.borders');

          const style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = `.animation .image-slide.shrink-down {
                    width:${imageWidth}px;
                    height:${imageHeight}px;
                    top:${offsetY}px;
                    left:${offsetX}px;
                  }`;
          document.head.appendChild(style);

          imageSlides.forEach(slide => slide.classList.add('shrink-down'));

          const xBottom = borders.querySelector('.x-bottom');
          xBottom.style.height = `${window.innerHeight - offsetY - imageHeight}px`;

          const xTop = borders.querySelector('.x-top');
          xTop.style.height = `${offsetY}px`;

          const yLeft = borders.querySelector('.y-left');
          yLeft.style.width = `${offsetX}px`;

          const yRight = borders.querySelector('.y-right');
          yRight.style.width = `${window.innerWidth - offsetX - imageWidth}px`;
        }, 3500);

        setTimeout(() => {
          document.querySelector('.intro__block').classList.add('visible');
          document.querySelector('.page-header').classList.add('visible');
        }, 4500);

        setTimeout(() => {
          document.querySelector('body').style.overflow = 'visible';
          document.querySelector('body').style.maxHeight = 'none';
        }, 6000);

        setTimeout(() => {
          document.querySelector('.animation').style.display = 'none';
        }, 8000);

        createCookie(cookie, 'intro', 1);
      });
    });
  } else {
    document.querySelector('.animation').style.display = 'none';
    document.querySelector('.intro__block').classList.add('visible');
    document.querySelector('.page-header').classList.add('visible');
  }
}
