// eslint-disable-next-line import/no-extraneous-dependencies
import Video from '@esign/video';
import { readCookie, createCookie } from '../cookies';

export default function initializeXmasModal() {
  const modalId = 'xmas-modal';
  const modal = window.modalManager.idModalMap.get(modalId);
  const videoEl = document.querySelector('.js-xmas-video');
  const modalTrigger = document.querySelector('[data-modal-id="xmas-modal"]');
  let autoPlay = false;

  const handleXmasModal = () => {
    modal.show();

    new Video(videoEl);

    modal.on('show', () => {
      document.querySelector('.js-xmas-wrapper').appendChild(videoEl);

      if (autoPlay) {
        document.querySelector('.video__overlay').click();
      }
    });

    modal.on('hide', () => {
      videoEl.remove();
    });
  };

  const handleModalClick = () => {
    autoPlay = true;
    handleXmasModal();
  };

  if (modal) {
    const cookieName = 'huysewinkel-popup-xmas';
    const home = document.querySelector('.home');
    const shouldAutoOpenModal = !readCookie(cookieName);

    if (modalTrigger && !home) {
      modalTrigger.addEventListener('click', handleModalClick);
    }

    if (shouldAutoOpenModal && !home) {
      handleXmasModal();
      createCookie(cookieName, 'popup', 2);
    }
  }
}
