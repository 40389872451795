import introAnimation from '../components/layout/introAnimation';

export default class Home {
  constructor() {
    introAnimation();

    const introImage = document.querySelector('.intro-image');
    const image = new Image();
    if (introImage.querySelector('img')) {
      image.src = introImage.querySelector('img').getAttribute('src');
      image.addEventListener('load', () => {
        introImage.classList.add('display');
      });

      image.addEventListener('error', () => {
        console.log('There was an error loading the image');
      });
    }

    const getGalleryItems = document.querySelectorAll('[data-gallery-item]');
    const getGalleryTriggers = document.querySelectorAll('.js-gallery-trigger');
    getGalleryTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();

        let index = 0;
        if (trigger.dataset.galleryStartIndex) index = trigger.dataset.galleryStartIndex;
        getGalleryItems[index].click();
      });
    });

    const handleModalAttributesChange = (mutationRecords, mapModal) => {
      mutationRecords.forEach((record) => {
        const video = mapModal.querySelector('video');

        if (record.type === 'attributes' && record.target.classList.contains('modal--active')) {
          if (video) video.play();
        }

        if (!record.target.classList.contains('modal--active')) {
          if (video) {
            video.pause();
            video.currentTime = 0;
          }
        }
      });
    };

    const videoModal = document.querySelector('.js-modal[id="video-modal"]');
    if (videoModal) {
      const observer = new MutationObserver(mutationRecords => handleModalAttributesChange(mutationRecords, videoModal));
      observer.observe(videoModal, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
      });
    }
  }
}
